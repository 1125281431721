import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"

const SecondaryPost = post => {
  const title = post.frontmatter.title || post.fields.slug
  return (
    <article
      className="mb-4"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <div className="md:shrink-0">
          <Link to={`/${post.fields.slug}`} itemProp="url" className="h-64 w-full object-cover md:h-full md:w-64">
            <GatsbyImage image={post.frontmatter.image.childImageSharp.gatsbyImageData} className="h-64 w-full object-cover md:h-full md:w-64 hover:brightness-100 brightness-75 transition-all rounded-md" />
          </Link>
        </div>
        <h2 className="text-md font-semibold mr-2">
          <Link to={`/${post.fields.slug}`} itemProp="url">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small className="text-slate-600 text-xs font-thin">{post.fields.date}</small>
      </header>
      <section className="text-sm">
        <p
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
          itemProp="description"
        />
      </section>
    </article>
  )
}

const RestOfPosts = post => {
  const title = post.frontmatter.title || post.fields.slug
  return (
    <article
      className="mb-2"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <h2 className="text-sm">
          <Link to={`/${post.fields.slug}`} itemProp="url">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small className="text-slate-600 text-xs font-thin">{post.fields.date}</small>
      </header>
    </article>
  )
}

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <header className="text-slate-600">
        <div className="mb-4">
          <h1 className="text-2xl font-semibold mb-2">📄 Blog</h1>
          <p className="text-sm">
            Escribo sobre código, diseño, tecnología y otras cosas que roben mi atención. Todos los artículos están en español.
          </p>
        </div>
      </header>

      <section className="post-list text-slate-700">
        <div>
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-8 border-b-2 border-slate-200 mb-3">
            {posts.slice(0, 9).map((post, index) => {
              return (
                <div key={post.fields.slug}>
                  <SecondaryPost {...post} />
                </div>
              )
            })}
          </div>

          {posts.slice(9, posts.length - 1).map((post, index) => {
            return (
              <div key={post.fields.slug} className="border-b-2 border-slate-200 mb-3">
                <RestOfPosts {...post} />
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Blog" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
          date(formatString: "LL", locale: "es")
        }
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
              gatsbyImageData(quality: 95, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
